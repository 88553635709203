<template>
    <div class="container">
        <v-card>
            <v-card-title class="title font-weight-light">
                <s-toolbar color="primary" dark label="Generacion de Guias">

                </s-toolbar>
            </v-card-title>
            <v-card-text>
                <v-row justify="center">
                   
                    <v-col cols="auto" >
                        <s-date label="Fecha Inicio"
                            v-model="item.DateBegin"
                            @input="searchitemsSend($event)"
                            >
                        </s-date>
                    </v-col>
                    <v-col cols="auto">
                        <s-date label="Fecha Fin"
                            v-model="item.DateEnd"
                            @input="searchitemsSend($event)"
                            >
                        </s-date>
                    </v-col>
            
                    <v-col cols="auto">
                        <s-select-client
                            clearable
                            label="Cliente"
                            v-model="item.CumID"
                            ref="txtCumID"
                            full
                            @input="searchitemsSend($event)"
                        >
                        </s-select-client>
                    </v-col> 
                    <v-col cols="auto">                              
                        <s-select-definition
                            clearable
                            label="Tipo corte"
                            :def="1429"
                            v-model="item.TypeCut"
                            @input="searchitemsSend($event)"
                        ></s-select-definition>    
                    </v-col>
                    <v-col cols="auto">
                        <s-select-definition
                            clearable
                            label="Tipo Empaque"
                            v-model="item.TypePacking"
                            :def="1435"
                            @input="searchitemsSend($event)"
                        ></s-select-definition>
                    </v-col>
                    <v-col cols="auto">
                        <s-select-definition
                            clearable
                            :def="1173"
                            v-model="item.TypeCultive"
                            label="Cultivo"
                            @input="searchitemsSend($event)"
                            ></s-select-definition>    
                    </v-col>
                </v-row>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table 
                            dense
                            :headers="headersPrePacking"
                            :items="itemsPrePacking"
                            :items-per-page="-1"
                            disable-sort>
                            <template v-slot:item.picking="{ item }">
                                <v-btn
                                    v-if="item.IsDispatch == 1"
                                    fab
                                    color="#23BAC4"
                                    x-small                                        
                                    depressed
                                    @click="ShowdialogDispatch(item)">
                                    <v-icon class="mdi-20px mdi-light ">fas fa-ship</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="item.IsDispatch == 1"
                                    fab
                                    color="green"
                                    x-small                                        
                                    depressed
                                    @click="ShowViewdialogDispatch(item)">
                                    <v-icon  class="mdi-22px mdi-light">mdi-printer</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog 
            v-model="dialogDispatch"
            v-if="dialogDispatch"
            fullscreen
            transition="dialog-bottom-transition"
            persistent>
            <packing-dispatch :SelectPrePacking="SelectPrePacking" @closedialogDispatch="closedialogDispatch()"></packing-dispatch>
        </v-dialog>
        <v-dialog v-model="dialogPrintPdf"
            v-if="dialogPrintPdf"     
            fullscreen       
            transition="dialog-bottom-transition">
                <dispatch-pdf @closedialogPrintPdf="closedialogPrintPdf()" :PrePackingPdf="PrePackingPdf"></dispatch-pdf>
        </v-dialog>
    </div>
</template>

<script>
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js"; 
    import PackingDispatch from "./FrzChamberPrePackingDispatch.vue";
    import DispatchPdf from "./FrzChamberDispatchPdf.vue";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    export default {
        components:{ PackingDispatch, DispatchPdf, SSelectClient},
        data(){
            return{
                headersPrePacking: [
                    { text: "Picking", value: "picking" },  
                    { text: "ID", value: "CppCorrelative" },                    
                    { text: "Fecha", value: "CppDateCreateString" },
                    { text: "Cliente", value: "CumName" },
                    { text: "Cajas", value: "NumberBox"},
                    { text: "Cultivo", value: "TypeCultiveName" },
                    { text: "Variedad", value: "VrtDescription" },
                    { text: "Presentacion", value: "TypeCutName" },
                    { text: "Empaque", value: "TypePackingName" },
                    { text: "N° Version", value: "versionnumber" },
                    { text: "Embarque", value: "numberShipment" },
                    
                ],
                DateBegin: "",
                DateEnd: "",
                itemsPrePacking: [],
                dialogDispatch: false,
                dialogPrintPdf: false,
                SelectPrePacking: {},
                item: {}
            }
        },
        methods:{
            searchitemsSend(event)
            {
                this.initialize();
            },
            initialize()
            {
                
                _sPrePacking.listpickingDispatch( this.item, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.itemsPrePacking = r.data;                                            
                    }
                })
            },
            ShowdialogDispatch(item){
                this.dialogDispatch = true;
                this.SelectPrePacking = item;          
                
            },
            closedialogDispatch()
            {
                this.dialogDispatch = false;
                this.initialize();
            },
            ShowViewdialogDispatch(item){
                this.dialogPrintPdf = true;
                this.PrePackingPdf = item;
                
            },
            closedialogPrintPdf()
            {
                this.dialogPrintPdf = false;
                this.initialize();
            }
        },
        mounted(){
            this.initialize();
        }
    }
</script>